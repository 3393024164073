import React from "react";
import Navbar from "./component/Navbar/Navbar";
import Banner from "./component/Banner/Banner";
import About from "./component/About";
import Service from "./component/Service";
import Testimonial from "./component/Testimonial/Testimonial";
import Clientslogo from "./component/Clientslogo";
import Blog from "./component/Blog/Blog";
import Contact from "./component/Contact";
import Skill from "./component/Skill/Skill";
import Footer from "./component/Footer";
import Data from "./component/Data";

const Home = () => {
  return (
    <div className="body_wrapper">
      <Navbar
        mContainer="custome_container"
        mainlogo="logo2.png"
        stickylogo="logo.png"
      />
      <Banner Data={Data} />
      <About aClass="about_area" Data={Data} />
      <Service wClass="work_area" Data={Data} />
      <Skill />
      <Testimonial />
      <Clientslogo />
      <Blog />   
      <Contact Data={Data} />
      <Footer Data={Data} />
    </div>
  );
};

export default Home;
