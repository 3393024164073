const Data = {
  name: "Bret Bickham",
  role: "Senior Full Stack Developer,Azure Solutions Expert, AWS Expert, and DevOps Expert.",
  ptext: "Software Engineering and Project Management Consultancy.",
  socialLinks: [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/dev-creativity-llc/",
      className: "social_linkedin",
    },
  ],
  aboutme: "About Us",
  aboutdetails: <h2>Software engineering consultancy Agency dedicated to meeting all clients needs</h2>,
  services: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails: "Working with this consultancy was a game-changer for our clients. Their software engineering team not only streamlined our development process but also introduced cutting-edge technologies that put us ahead of our competitors. Their project management skills ensured timely delivery and exceptional quality. Our latest product launch was a huge success, and I attribute a significant part of that to their expertise and dedication.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails: "As a company focusing on sustainable technology, we needed a partner with a proven track record. This consultancy provided not only the technical expertise but also the strategic vision to help us navigate our project complexities. Their team was exceptionally communicative, always keeping us in the loop, and their solutions were both innovative and practical. They were instrumental in helping us meet our project goals on time and within budget.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Project Management",
      sDetails: "In the fast-paced world of financial technology, having a reliable software engineering and project management consultant is crucial. This team's proficiency in the latest tech and their ability to manage complex projects efficiently has been invaluable. They've consistently delivered high-quality results, and their attention to detail has helped us avoid many potential pitfalls. They are more than consultants; they are strategic partners in our success.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy and Consulting",
      sDetails: "Many industries demand precision, security, and innovation. Our consultancy excelled in all these areas, providing us with top-notch software solutions that are both user-friendly and compliant with industry regulations. Their project managers were exceptional, consistently going above and beyond to ensure our projects were on track. Their contributions have been pivotal in enhancing our patient care systems.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Azure/AWS/DevOps",
      sDetails: "We have been using cloud technologies for over 10 years.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails: "As we embarked on digital transformation, we needed a partner who could guide us through the complexities of software development while ensuring our projects aligned with our business goals. This consultancy surpassed our expectations. Their engineers brought in fresh, innovative ideas, and their project managers were adept at translating technical jargon into business terms. They played a crucial role in the successful launch of our e-commerce platform.",
    },
  ],  
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Denver, Colorado",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: 970-694-7274",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: bretbickham@gmail.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Software Engineering and Project Management Consultancy",
      title: "We develop and design with passion",
      discription: "It's our companies mission to bring your creativity to life.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion for what we do",
      aHeadTwo: "Every project is different.",
      adiscription: "Since beginning of the companies journey as a consultancy company nearly 10 years ago, we've done remote work/hybrid/on-site projects for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Full Stack Engineering/Development",
      text: "Create digital products with unique idea",
      complateProject: "81 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "Front-End/Back-End Development",
      text: "Our teams coding structure is unmatched.",
      complateProject: "73 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "Project Management",
      text: "Boost your business with project management who knows the in's and out's of the development lifecycle.",
      complateProject: "34 Projects",
      icon: "icon-linegraph",
    },
  ],
  contactDetails: [
    {
      id: 1, 
      icon: "icon_pin_alt",
      text: "Denver, Colorado",
    },
    {
      id: 2,
      icon: "icon_phone",
      text: "Phone: 970-694-7274",
    },
    {
      id: 3, 
      icon: "icon_mail",
      text: "bretbickham@gmail.com",
    },
    {
      id: 4,
      icon: "icon_globe",
      text: "https://www.linkedin.com/company/dev-creativity-llc/",
    },
  ],
  contactForm: [
    {
      id: 1,
      name: "Name",
      email: "Email",
      subject: "Subject",
    },
    {
      id: 2,
      message: "Message",
      submit: "Send Message",
      url: "mailto:bretbickham@gmail.com",
    },
  ]
};
/**
 * Represents the Data component.
 * @module Data
 * @default
 */
export default Data;
