import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";
class Sectiontitle extends Component {
  render() {
    var { Title, TitleP } = this.props;
    return (
      <div className="section_title text-center mb_60">
        <Fade direction="up" duration={500}>
          <h2 className="mb_0 title_h2 t_color">{Title}</h2>
        </Fade>
        <Fade direction="up" duration={1000}>
          <p className="mb_0 title_p">{TitleP}</p>
        </Fade>
        <Fade direction="up" duration={1500}>
          <span className="bottom_line"></span>
        </Fade>
      </div>
    );
  }
}
export default Sectiontitle;
