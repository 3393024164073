import React, { Component } from "react";
import Sectiontitle from "../Banner/Sectiontitle";
import BlogItems from "../Blog/BlogItems";
import { Fade } from "react-awesome-reveal";
class Blog extends Component {
  render() {
    return (
      <section className="blog_area" id="blog">
        <div className="container">
          <Sectiontitle
            Title="Our Blog"
            TitleP="Under Construction"
          />
          <Fade direction="up" cascade triggerOnce duration={1000}>
            <div className="row">
              <BlogItems
                bTitle="Development"
                bDetails="Latest development news and updates"
                btnText="Read More"
                image="post-img1.jpg"
                Pdata="Jan 14"
              />
              <BlogItems
                bTitle="Project Management"
                bDetails="How to get your PMP certification"
                btnText="Read More"
                image="post-img2.jpg"
                Pdata="Jan 30"
              />
              <BlogItems
                bTitle="Integrating with AI"
                bDetails="How to integrate with AI and machine learning"
                btnText="Read More"
                image="post-img3.jpg"
                Pdata="Feb 1"
              />
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}
export default Blog;
