import React, { Component } from 'react';
import Clientsliders from 'react-slick';
import Sectiontitle from '../component/Banner/Sectiontitle';

class Clientslogo extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024, // Desktop breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768, // Tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480, // Mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="clients_logo_area">
        <div className="container">
          <Sectiontitle Title="Trusted by Great People" TitleP="Established in 2016" />
          <Clientsliders {...settings} className="clients_slider">
            <div className="item">
              <a href=".#">
                <img src={require('../image/1.png')} alt="" />
              </a>
            </div>
            {/* <div className="item">
              <a href=".#">
                <img src={require('../image/2.png')} alt="" />
              </a>
            </div> */}
            <div className="item">
              <a href=".#">
                <img src={require('../image/3.png')} alt="" />
              </a>
            </div>
          </Clientsliders>
        </div>
      </section>
    );
  }
}

export default Clientslogo;