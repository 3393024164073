import React, {Component} from 'react';
import Slider from 'react-slick';

 class TestimonialSlider extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        pauseOnHover:true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <div>
          <Slider {...settings} className="testimonial_slider">
                <div className="item">
                    
                    <h6>Alex Smith</h6>
                    <span>Dev Creativity Customer</span>
                    <span>Head of Operations at HealthCare Dynamics</span>
                    <p>"The healthcare industry demands precision, security, and innovation. 
                      Dev Creativity excelled in all these areas, providing us with top-notch software solutions 
                      that are both user-friendly and compliant with industry regulations. Their project managers were exceptional, 
                      consistently going above and beyond to ensure our projects were on track. 
                      Their contributions have been pivotal in enhancing our patient care systems. Thank you for your expertise!"</p>
                </div>
                <div className="item">
                    <h6>Sophia Evans</h6>
                    <span>CTO</span>
                    <p>"Working with Dev Creativity was a game-changer for our tech startup. 
                      Their software engineering team not only streamlined our development process but also introduced cutting-edge technologies that put us ahead of our competitors. Their project management skills ensured timely delivery and exceptional quality. 
                      Our latest product launch was a huge success, and I attribute a significant part of that to their expertise and dedication."</p>
                </div>
                <div className="item">
                    <h6>Michael James</h6>
                    <span>Project Manager at Green Solutions</span>
                    <p>"As a company focusing on sustainable technology, we needed a partner who could understand our unique challenges. 
                      Dev Creativity provided not only the technical expertise but also the strategic vision to help us navigate our project complexities. 
                      Their team was exceptionally communicative, 
                      always keeping us in the loop, and their solutions were both innovative and practical. They were instrumental in 
                      helping us meet our project goals on time and within budget."</p>
                </div>
          </Slider>
        </div>
      );
    }
  }
  export default TestimonialSlider;