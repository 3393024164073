import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";



class About extends Component {
  render() {
    let Data = this.props.Data;
    var { aClass } = this.props;
    return (
      <section className={`${aClass}`} id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <Fade direction="up" triggerOnce="true" damping={0.1}>
                <div className="about_content">
                  <h2 className="t_color">{Data.aboutme}</h2>
                  <h6>{Data.role}</h6>
                  <p>{Data.aboutdetails}</p>
                  <a href="https://www.linkedin.com/company/dev-creativity-llc/" className="theme_btn active">
                    Hire us
                  </a>
                  <a href="https://www.linkedin.com/company/dev-creativity-llc/" className="theme_btn">
                    Download CV
                  </a>
                </div>
              </Fade>
            </div>
            <div className="col-lg-5">
              <div className="about_img">
                <Fade direction="right" duration={1500}>
                  <img src={require("../image/apporoach_man_img.png")} alt="" />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
