import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
/*-----pages-----*/
import Home from "./Home";
// import Home2 from "./Home2";
// import Home3 from "./Home3";
// import Home4 from "./Home4";
// import Home5 from "./Home5";
// import Home6 from "./Home6";
// import Homefrelencer from "./Home-frelencer";
// import HomeDeveloper from "./Home-developer";
import NotFound from "./404";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/Home2" element={<Home2 />} />
          <Route path="/Home3" element={<Home3 />} />
          <Route path="/Home4" element={<Home4 />} />
          <Route path="/Home5" element={<Home5 />} />
          <Route path="/Home6" element={<Home6 />} /> */}
          {/* <Route path="/Homefrelencer" element={<Homefrelencer />} />
          <Route path="/HomeDeveloper" element={<HomeDeveloper />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
