import { sendForm } from "@emailjs/browser";
import { Component } from "react";


class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: "",
        subject: "",
        phone: "",
        email: "",
      },
    };
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name = value.length === 0 ? "Name is not empty" : "";
        break;
      case "subject":
        errors.subject = value.length < 5 ? "Subject must be 5 characters" : "";
        break;
      case "phone":
        errors.phone = value.length < 5 ? "phone is not empty" : "";
        break;
      case "email":
        errors.email = value.length < 5 ? "Subject is not empty" : "";
        let appos = value.indexOf("@");
        let dots = value.lastIndexOf(".");

        if (appos < 1 || dots - appos < 2) {
          errors.email = "please enter valid email";
        }
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  sendForm = () => {
    this.sendForm("service_9l5w6j3", "template_xb7l9z9", "user_9Y4E4gZDhRjvQpFhZS6Rw", {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      phone: this.state.phone, 
      message: this.state.message,

    }).then(
      function (response) {
        return (
        <div>
          <a href="emailto:bretbickham@gmail.com">Thank you</a>
        </div>
        );
      }
    )
      .catch(
        function (error) {
          <div id="error">Opps! Something went wrong. Please try again.</div> 
        }
      )
  }

  submitHandler = (e) => {
    e.preventDefault();
    if (
      this.state.errors.name.length === 0 &&
      this.state.errors.subject.length === 0 &&
      this.state.errors.phone.length === 0 &&
      this.state.errors.email.length === 0
    ) {
      // eslint-disable-next-line no-unused-expressions
      sendForm
      alert("form is submitted");
    } else {
      alert("form is invalid");
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <form onSubmit={this.submitHandler.bind(this)} className="form_class">
        <h2>Contact Form</h2>
        <div className="row">
          <div className="col-lg-6">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Your Name*"
              onChange={this.handleChange}
            />
            <p>{errors.name}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Your Email*"
              onChange={this.handleChange}
            />
            <p>{errors.email}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              id="subject"
              name="subject"
              className="form-control"
              placeholder="Subject*"
              onChange={this.handleChange}
            />
            <p>{errors.subject}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Phone*"
              onChange={this.handleChange}
            />
            <p>{errors.phone}</p>
          </div>
        </div>
        <textarea
          name="message"
          id="message"
          className="form-control"
          rows="6"
          placeholder="Your Message ..."
          onChange={this.handleChange}
        ></textarea>
        <button type="submit" onSubmit={this.submitHandler} className="btn send_btn theme_btn">
         Send Message
        </button>
      </form>
    );
  }
}

export default Form;
