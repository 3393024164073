import React, { Component } from "react";
import { Parallax } from "react-parallax/";
import { Fade } from "react-awesome-reveal";
const image = require("../../image/profile.jpg");

class Banner extends Component {
  render() {
    let Data = this.props.Data;
    return (
      <section id="home">
        <Parallax bgImage={image} strength={500} className="banner_area">
          <div className="container">
            <div className="banner_content">
              <Fade direction="up">
                <h5>Welcome</h5>
              </Fade>
              <Fade direction="up" duration={1500}>
                <h2>Dev Creativity</h2>
              </Fade>
              <Fade direction="up" duration={1500}>
                <h4 className="wow fadeInUp animated">
                  Software Engineering Consultancy
                </h4>
              </Fade>
              <Fade direction="up" cascade duration={1000} damping={0.1}>
                <ul className="list_style social_icon">
                  {Data.socialLinks &&
                    Data.socialLinks.map((item) => {
                      return (
                        <li key={item.name}>
                          <a href={item.url}>
                            <i className={item.className}></i>
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </Fade>
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}

export default Banner;
