import React from "react";

const ServicesThree = () => (
            <div className="dc_service_list">
                <ul>
                    <li>
                        <h3>Custom Software Development:</h3>
                            <p>
                        Bespoke Solutions: Crafting tailor-made software applications to meet specific client needs.
                        Full Lifecycle Management: Handling all stages of software development from concept, design, implementation, to maintenance.
                        Technology Expertise: Utilizing a wide range of technologies including modern programming languages, frameworks, and databases.
                    </p>        
                    </li>
                    <br/>
                    <li>
                        <h3>Software Upgrade and Modernization:</h3>
                    <p>
                        Legacy System Overhaul: Upgrading and modernizing outdated software systems to enhance performance, security, and functionality.
                        Technology Migration: Assisting in the transition from older technologies to cutting-edge platforms and architectures.
                        Refactoring Services: Improving the structure of existing code without changing its external behavior to enhance nonfunctional attributes.
                    </p>
                    </li>
                    <br/>
                    <li>
                        <h3>Cloud Computing Services:</h3>
                    <p>
                        Cloud Migration: Facilitating the shift of enterprise applications and data to cloud platforms.
                        Cloud-Native Development: Building and deploying scalable and resilient applications designed for the cloud environment.
                        Cloud Strategy and Consulting: Offering strategic guidance on cloud adoption, implementation, and optimization.
                    </p>
                    </li>
                    <br/>
                    <li>
                        <h3>Mobile Application Development:</h3>
                    <p>                    
                        Cross-Platform Apps: Designing and developing applications for multiple platforms like iOS and Android.
                        Native Application Development: Creating platform-specific applications for optimal performance.
                        UI/UX Design: Crafting user-friendly and engaging mobile application interfaces.
                        </p>
                    </li>
                    
                    <br/>
                    <li>
                        <h3>Artificial Intelligence Services:</h3>
                    <p>                   
                        AI-Driven Development: Integrating artificial intelligence into applications for enhanced decision-making, automation, and personalization.
                        Machine Learning Models: Developing and deploying machine learning models for predictive analytics and data-driven insights.
                        Natural Language Processing: Implementing NLP capabilities for chatbots, text analysis, and voice recognition.
                        </p>
                        </li>
                        <br/>
                    <li>
                        <h3>IoT Solutions:</h3>
                        <p>
                        IoT Application Development: Building applications that leverage IoT technology for smart devices and systems.
                        IoT Architecture Design: Creating robust architectures to support large-scale IoT deployments.
                        Data Analytics and Visualization: Analyzing and visualizing data collected from IoT devices for actionable insights.
                        </p>
                        </li>
                        <br/>
                    <li>
                        <h3>Cybersecurity Services:</h3>
                        <p>
                        Security Assessment: Conducting thorough security audits and vulnerability assessments.
                        Compliance and Risk Management: Ensuring software compliance with industry standards and managing cybersecurity risks.
                        Incident Response: Offering rapid response services for cybersecurity incidents and breaches.
                        </p>
                        </li>
                    <li>
                        <h3>DevOps and Agile Consulting:</h3>
                        <p>
                        DevOps Implementation: Streamlining development and operations for faster, more efficient deployment cycles.
                        Agile Transformation: Assisting organizations in adopting and optimizing agile methodologies.
                        Continuous Integration/Continuous Deployment (CI/CD): Setting up and maintaining CI/CD pipelines for automated testing and deployment.
                        </p>
                        </li>
                        <br/>   
                        <li>
                        <h3>Quality Assurance and Testing:</h3>
                        <p>
                            Automated Testing Services: Implementing automated testing frameworks for efficient and thorough software testing.
                            Performance Testing: Ensuring software stability and scalability under various conditions.
                            User Acceptance Testing (UAT): Facilitating UAT to ensure software meets client requirements and expectations.
                            </p>
                        </li>
                        <br/>
                        <li>
                        <h3>Training and Support:</h3>
                        <p>
                        Technical Training: Offering training sessions to client teams on new technologies and software practices.
                        Ongoing Support and Maintenance: Providing continual support and maintenance services post-deployment.
                        Documentation and Knowledge Transfer: Delivering comprehensive documentation and ensuring knowledge transfer for client independence.
                        </p>
                        </li>
                        <br/>
                        <li>
                        <h3>Client-Centric Approach:</h3>
                        <p>
                        Tailored Solutions: Each service is customized to address the unique challenges and objectives of the client.
                        Consultative Engagement: Working closely with clients to understand their business processes and technology needs.
                        Continuous Collaboration: Ensuring transparent and continuous communication throughout the project lifecycle.
                        Note: The specific services offered can vary depending on the consultancy's expertise, technology focus, and the evolving needs of the software industry.
                        </p>    
                        </li>
                    </ul>
                </div>
  );

    export default ServicesThree
                      